import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Box, Button } from 'rebass/styled-components';
import styled from 'styled-components';

import { ArrowIcon } from './ArrowIcon';
import PortfolioFeatured from './PortfolioFeatured';

const Container = styled(Box)`
  background-color: #f4f3ef;
  padding: 32px 0 64px;

  @media (max-width: 600px) {
    padding: 0 30px;
  }

  .wrapper {
    width: 100%;
    position: relative;
  }

  /* Our Portfolio */
  .our-portfolio {
    max-width: 1440px;
    margin: 0 auto;
    padding: 32px 100px 0;

    @media (max-width: 1400px) {
      padding: 24px 30px 8px;
    }

    @media (max-width: 600px) {
      padding: 0;
    }

    & + .our-portfolio {
      margin-bottom: 60px;

      @media (max-width: 1000px) {
        margin-bottom: 32px;
      }

      @media (max-width: 600px) {
        margin-top: 24px;
      }
    }
  }

  .our-portfolio--acquired {
    .our-portfolio__header {
      border-top: none;
      padding-top: 0;
    }
  }

  .our-portfolio__header {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid #d5cfc9;

    @media (max-width: 700px) {
      border-top: 0;
      padding: 16px 0;
    }
  }

  .our-portfolio__title,
  .our-portfolio__dates {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 2px;
    color: #a59d94;
    margin: 24px auto 32px 0;
  }

  .our-portfolio__dates {
    margin-right: 0;

    @media (max-width: 500px) {
      display: none;
    }
  }

  .our-portfolio__link {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.5px;
    color: #a59d94;
    transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    &:hover {
      color: #1eafa3;

      .arrow-icon {
        transform: translate(4px, -4px);
      }
    }

    .arrow-icon {
      width: 12px;
      height: 12px;
      margin-left: 4px;
      transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      path {
        stroke-width: 6px;
      }
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  .companies {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
    }
  }

  .company {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: border 300ms cubic-bezier(0.65, 0.05, 0.36, 1),
      box-shadow 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    &:hover {
      border: 1px solid #1bb2a6;
      box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.03),
        0px 8px 24px rgba(17, 17, 26, 0.03);

      .company__name {
        color: #1bb2a6;
      }

      .company__field:before {
        transform: translateY(-100%);
      }

      .company__field:after {
        transform: translateY(0);
      }

      .arrow-icon {
        opacity: 1;
        transform: translateX(0);

        path {
          stroke: var(--color);
        }
      }
    }

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 12px;
    }

    .arrow-icon {
      position: absolute;
      right: 24px;
      top: 24px;
      width: 20px;
      height: 20px;
      opacity: 0;
      transform: translateX(10px);
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 600px) {
        width: 16px;
        height: 16px;
        top: 16px;
        right: 14px;
        opacity: 1;
        transform: translateX(0);
      }

      path {
        stroke: var(--color);
        stroke-width: 2px;

        @media (max-width: 600px) {
          opacity: 1;
          transform: translateX(0);
          stroke: currentColor;
          stroke-width: 3px;
        }
      }
    }
  }

  .company--view-all {
    background-color: #1eafa3;
    color: #ffffff;
    height: 72px;
    align-items: center;
    display: none;

    @media (max-width: 700px) {
      display: flex;
    }

    &:hover {
      .company__name {
        color: #ffffff;
      }
    }

    .company__name {
      margin-bottom: 1px;
    }

    .arrow-icon {
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }
  }

  .company__name {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: 21px;
    line-height: 1;
    transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    margin: 0 0 16px;
  }

  .company__field {
    position: relative;
    overflow: hidden;
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 8px;
    letter-spacing: 1px;
    line-height: 8px;
    text-transform: uppercase;
    color: #a59d94;
    height: 8px;
    margin: 0;
    margin-top: auto;

    &:before,
    &:after {
      position: absolute;
      transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
      backface-visibility: hidden;
    }

    &:before {
      content: attr(data-company-field);
    }

    &:after {
      content: 'Visit Site';
      transform: translateY(100%);
    }
  }

  .our-portfolio__filter {
    color: #a59d94;
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    border-radius: 16px;
    border: 1px solid #979797;
    transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
    background: transparent;
    margin-left: 10px;

    &:hover {
      color: #1eafa4;
      border-color: #1eafa4;
      cursor: pointer;
    }

    &.selected {
      background-color: #1eafa4;
      border-color: #1eafa4;
      color: #fff;
    }
  }
`;

const Company: React.FC<any> = props => {
  const {
    name: companyName,
    company_field: companyField,
    company_website: url,
  } = props.company.primary;

  const item = {
    hidden: { y: 20, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.65, 0.05, 0.36, 1],
      },
    },
  };

  return (
    <motion.li className="company" variants={item}>
      <a href={url.url} target={url.target}>
        <p className="company__name">{companyName}</p>
        <p className="company__field" data-company-field={companyField} />
        <ArrowIcon color="#1bb2a6" />
      </a>
    </motion.li>
  );
};

interface Filter {
  title: string;
  selected: boolean;
}

const OurPortfolio: React.FC<any> = ({ data }) => {
  const showViewAllBtn = false;
  const filters = ['Health & Wellness', 'Future of Work & Money', 'Experience & Connection', 'Media & Attention'];
  const [selected, setSelected] = useState<string[]>([]);
	const [companies, setCompanies] = useState(data.body);

  const handleSelect = useCallback(
    (value: string) => {
      const newSelected = [...selected];
      const index = newSelected.findIndex(item => item === value);

      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(value);
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const isSelected = useCallback(
    (item: string) => {
      const things = selected.find(value => value === item) ? 'selected' : '';
      return things;
    },
    [selected]
  );

	useEffect(() => {
		data.body.filter((item) => {

		})
	}, [selected]);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.08,
      },
    },
  };

  return (
    <>
      <Container>
        <div className="wrapper">
          <PortfolioFeatured data={data} />
          <div className="our-portfolio">
            <div className="our-portfolio__header">
              <h2 className="our-portfolio__title">Our Portfolio</h2>
              <Box
                sx={{
                  display: ['none', 'none', 'flex'],
                  flexDirection: 'row',
                }}
              >
                {filters.map((item, i) => (
                  <Button
                    key={i}
                    onClick={() => {
                      handleSelect(item);
                    }}
                    className={`our-portfolio__filter ${isSelected(item)}`}
                  >
                    {item}
                  </Button>
                ))}
              </Box>
            </div>

            <InView triggerOnce rootMargin="-75px 0px">
              {({ inView, ref }) => (
                <>
                  <div ref={ref} />
                  <motion.ul
                    className="companies"
                    variants={container}
                    initial="hidden"
                    animate={inView ? 'show' : 'hidden'}
                  >
                    {companies
                      .sort((a: any, b: any) =>
                        a.primary.name.toLowerCase() <
                        b.primary.name.toLowerCase()
                          ? -1
                          : a.primary.name.toLowerCase() >
                            b.primary.name.toLowerCase()
                          ? 1
                          : 0
                      )
                      .filter(
                        (company: any) => {

													if (selected.length === 0) {
														return true;
													}
													if (!company.primary.tags) {
														return false;
													}

													const items = selected.map((item) => item.replace('&', 'and'))
													const tags = company.primary.tags.split(',');

													return items.some(item => tags.includes(item));
												}
                      )
                      .map((company: any, i: number) => (
                        <Company company={company} key={i} />
                      ))}
                    {showViewAllBtn && (
                      <Link
                        to="/portfolio"
                        className="company company--view-all"
                      >
                        <p className="company__name">See Our Full Portfolio</p>
                        <ArrowIcon color="#ffffff" />
                      </Link>
                    )}
                  </motion.ul>
                </>
              )}
            </InView>
          </div>

          {/* <div className="our-portfolio our-portfolio--acquired">
            <div className="our-portfolio__header">
              <h2 className="our-portfolio__title">Acquired Companies</h2>
            </div>

            <InView triggerOnce rootMargin="-75px 0px">
              {({ inView, ref }) => (
                <>
                  <div ref={ref} />
                  <motion.ul
                    className="companies"
                    variants={container}
                    initial="hidden"
                    animate={inView ? 'show' : 'hidden'}
                  >
                    {companies
                      .sort((a: any, b: any) =>
                        a.primary.name.toLowerCase() <
                        b.primary.name.toLowerCase()
                          ? -1
                          : a.primary.name.toLowerCase() >
                            b.primary.name.toLowerCase()
                          ? 1
                          : 0
                      )
                      .filter(
                        (company: any) => company.primary.acquired_company
                      )
                      .map((company: any, i: number) => (
                        <Company company={company} key={i} />
                      ))}
                  </motion.ul>
                </>
              )}
            </InView>
          </div>*/}
        </div>
      </Container>
    </>
  );
};

export default OurPortfolio;
