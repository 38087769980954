import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Layout from '../components/Layout';
import OurPortfolio from '../components/OurPortfolio';

const PortfolioWrapper = styled(Box)`
  padding-top: 128px;
  background-color: #f4f3ef;
  height: 100%;

  @media (max-width: 750px) {
    padding-top: 88px;
  }
`;

const Portfolio: React.FC<any> = ({ data }) => {
  const portfolioData = data.allPrismicOurPortfolio.edges[0].node.data;

  return (
    <Layout headerBG="beige">
      <Helmet>
        <title>Our Portfolio</title>
      </Helmet>
      <PortfolioWrapper>
        <OurPortfolio data={portfolioData} />
      </PortfolioWrapper>
    </Layout>
  );
};

export default withPrismicPreview(Portfolio);

export const query = graphql`
  query {
    allPrismicOurPortfolio {
      edges {
        node {
          id
          data {
            body {
              ... on PrismicOurPortfolioDataBodyCompany {
                primary {
                  name
									tags
                  description {
                    html
                  }
                  company_color
                  company_field
                  company_image {
                    url
                  }
                  company_website {
                    target
                    url
                  }
                  featured_company
                  acquired_company
                }
                items {
                  founders
                }
              }
            }
          }
        }
      }
    }
  }
`;
